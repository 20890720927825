export const STATES = {
  INITIAL: 1,
  READY: 2,
  ATTACHING: 3,
  ATTACHED: 4,
  NOTALLOW: 5,
  RUNNING: 6,
  ENDED: 7,
  DESTROYED: 8
}

export const STATE_ARRAY = [
  'ERROR',
  'INITIAL',
  'READY',
  'ATTACHING',
  'ATTACHED',
  'NOTALLOW',
  'RUNNING',
  'ENDED',
  'DESTROYED'
]
